<template>
  <div class="pp-container">
    <h1 class="grey--text text-center">Privacy Policy</h1>
    <p class="text-center grey--text my-4">
      Eco Game Stadio (“EGS”) is committed to protecting the privacy of its
      users of the real-time strategy game “Lands of Azolite” (“Game”). This
      Privacy Policy explains how EGS collects, uses, and discloses information
      from users of the Game.
    </p>
    <ul>
      <li class="mb-5" v-for="(item, i) in paragraphes" :key="i">
        <h3 class="white--text">{{ item.title }}</h3>
        <p class="grey--text ">{{ item.text }}</p>
        <p class="grey--text" v-if="item.text2">{{ item.text2 }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paragraphes: [
        {
          title: "Collection of Information",
          text: "EGS collects information from users of the Game in order to provide the best possible gaming experience. This information includes, but is not limited to, user names, email addresses, IP addresses, and other information related to the Game.",
        },
        {
          title: "Use of Information",
          text: "EGS uses the information collected from users of the Game to provide the best possible gaming experience. This includes, but is not limited to, providing customer support, improving the Game, and providing personalized content.",
          text2:
            "EGS may also use the information collected from users of the Game to send promotional emails or other communications. Users may opt-out of receiving these communications at any time.",
        },
        {
          title: "Disclosure of Information",
          text: "EGS will not disclose any information collected from users of the Game to any third parties, except as required by law.",
        },
        {
          title: "Security",
          text: "EGS takes reasonable measures to protect the information collected from users of the Game from unauthorized access, use, or disclosure.",
        },
        {
          title: "Changes to this Privacy Policy",
          text: "EGS reserves the right to modify this Privacy Policy at any time. Any changes to this Privacy Policy will be posted on this page.",
        },
        {
          title: "Contact Us",
          text: "If you have any questions or concerns about this Privacy Policy, please contact us at support@ecosmartecs.com.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.pp-container {
  padding: 100px 10%;
  @media only screen and(max-width : 450px) {
    padding: 100px 5%;
    h1{
      font-size: 20px;
    }
    h3{
      font-size: 13px;

    }
    p{
      font-size: 12px;
    }
    
  }
  li {
    list-style: none;
  }
}
</style>
